import * as React from 'react';
import { graphql, Link } from 'gatsby';
import {
  Layout,
  SEO,
  LWrap,
  CSectTitle,
  LRestaurantContact2en,
  CHeroMedia02,
  CKv,
  CGoogleMap,
  CBgMedia,
  CInfinityMedia,
  CPickup,
  CNewsList,
  AssetImage,
  CBtn,
  CBtnList,
  CFixedImg,
  CFixBtnList02,
  CHeroMedia03,
} from '../../components/_index';
import infoChoice from '../../utils/info-choice';
import infoGet from '../../utils/info-get';
import pickupGet from '../../utils/pickup-get';
import { pickupChoice } from '../../utils/pickup-choice';
import { text } from 'stream/consumers';
import '../../assets/_sass/page/home.scss';

let titleBanner_jp_title = <>
営業一時休止のお知らせ
</>
let titleBanner_jp = <>
横浜ロイヤルパークホテルをご愛顧いただき、<br/>
誠にありがとうございます。<br/>
ビル大規模修繕工事にともない、<br/>
2025年3月31日（月）をもちまして<br/>
営業を一時休止いたしました。<br/>
2028年度、<br/>
リニューアルオープンを予定しておりますが、<br/>
再開業時期につきましては、<br/>
正式に決まり次第お知らせいたします。<br/>
お客様にはご迷惑をおかけいたしますが、<br/>
何卒ご理解賜りますようお願い申しあげます。<br/>
みなさまとの再会を<br/>
心よりお待ち申しあげております。<br/>
</>

let titleBanner_en_title = <>
Notification Regarding the Temporary <br/> Suspension of the Hotel
</>
let titleBanner_en = <>


Thank you for your continued patronage of the Yokohama Royal Park Hotel.<br className='u_pc'/>
Currently, the hotel has suspended operations<br className='u_pc'/>
due to extensive renovation work.<br className='u_pc'/>
The hotel is scheduled to be reopened in 2028,<br className='u_pc'/>
and when a date for the resumption of operations is formally determined,<br className='u_pc'/>
we will post the information on our homepage.<br className='u_pc'/>
We apologize for any inconvenience, and<br className='u_pc'/>
ask for your understanding and cooperation.<br className='u_pc'/>
We're looking forward to seeing you again at the Yokohama Royal Park Hotel.<br className='u_pc'/>

</>;


// markup
// const SubPage = () => {

  const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  let title = "[Official] Yokohama Royal Park Hotel";
  let description="Yokohama Royal Park Hotel official site."
  return (
    <Layout checken="en" checkjump="/">
      <SEO checken="en" title={title} description={description} />
      <CKv
        title={
          <>
          </>
        }
        title2={
          <>
          {titleBanner_en_title}
          </>
        }
        title3={
          <>
          {titleBanner_en}
          </>
        }
        
        
      
        data={[
          {
            img: {
              src: '/assets/images/home/index/kv.png',
            },
            imgSp: {
              src: '/assets/images/home/index/kv__sp.png',
            },
          },
          
        ]}
      />
      <section className={`l_sect`}>
        <LWrap>
          <CSectTitle title={{ ja: '', en: 'INFORMATION' }} />
          <CNewsList data={infoChoice(infoGet(), [], 5)} />
          <CBtnList
            data={[
              {
                label: 'MORE',
                link: {
                  href: '/information/',
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <LRestaurantContact2en />
    </Layout>
  );
};

export default SubPage;
